export default class Modal {
    $$trigger;
    $$triggerHide;
    $dialog;
    $el;
    isXhr;

    constructor($el) {
        this.$el = $el;
        this.isXhr = this.$el.dataset.modalXhr == 'true';
        const id = this.$el.id ?? null;

        if (id) {
            this.$$closeTriggers = this.$el.querySelectorAll('.js-modalClose, a[href="#modal:close"]');
            this.$$triggers = document.querySelectorAll(`[data-modal-open="${id}"], a[href="#modal:${id}"]`);
            this.$dialog = this.$el.querySelector('[role="dialog"]');
            this.initListeners();

            if (this.isXhr) {
                this.open();
            }
        }
    }

    close() {
        if (this.isOpen) {
            document.body.classList.remove('has-modal');
            this.$dialog.ariaModal = 'false';
            this.$el.querySelectorAll('form').forEach($form => $form.reset());
            this.$el.classList.add('is-hide');
        }
    }

    initListeners() {
        this.$el.addEventListener('click', this.onClick.bind(this), false);

        this.$el.addEventListener('modal.close', () => {
            this.close();
        });

        this.$$triggers.forEach($trigger => {
            $trigger.addEventListener('click', e => {
                e.preventDefault();
                this.open();
            });
        });

        this.$$closeTriggers.forEach($closeTrigger => {
            $closeTrigger.addEventListener('click', e => {
                e.preventDefault();
                this.close();
            });
        });

        this.$el.addEventListener(
            'animationend',
            e => {
                if (e.target === this.$el) {
                    this.$el.classList.remove('is-hide');
                    this.isOpen = false;

                    if (this.isXhr) {
                        this.$el.remove();
                    }
                }
            },
            false
        );

        document.addEventListener('keydown', this.onKeyDown.bind(this), false);
    }

    onClick(e) {
        if (e.target === e.currentTarget) {
            this.close();
        }
    }

    onKeyDown(e) {
        if (e.key === 'Escape') {
            this.close();
        }
    }

    open() {
        document.body.classList.add('has-modal');
        this.$dialog.ariaModal = 'true';
        this.isOpen = true;

        const $autofocus = this.$el.querySelector('[autofocus]');

        if ($autofocus) {
            window.requestAnimationFrame(() => {
                $autofocus.focus();
            });
        }
    }

    /**
     * Getters & setters
     */

    get isOpen() {
        return this.$el.classList.contains('is-open');
    }

    set isOpen(isOpen) {
        this.$el.classList.toggle('is-open', isOpen);
    }
}
