export default class Menu {
    $clone;
    $el;
    $list;
    bottom;
    offset;

    constructor($el) {
        this.$el = $el;
        this.$list = $el.querySelector('.js-menuList');
        this.listHeight = this.$list.getBoundingClientRect().height;
        this.offset = 100;

        const repeat = Math.ceil(window.innerHeight / this.listHeight);

        for (let i = 0; i < repeat; i++) {
            this.appendClone();
        }

        this.initListeners();
        this.onScroll();
    }

    appendClone() {
        const $clone = this.$list.cloneNode(true);
        this.$el.appendChild($clone);
    }

    initListeners() {
        window.addEventListener('scroll', this.onScroll.bind(this), false);
    }

    onScroll() {
        const menuBottom = this.$el.getBoundingClientRect().bottom;
        const scrollBottom = window.scrollY + window.innerHeight;

        if (scrollBottom > window.scrollY + menuBottom - this.offset) {
            this.appendClone();
        }
    }
}
