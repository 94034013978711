/**
 * Shopify Storefront API GraphiQL explorer
 * https://shopify.dev/docs/custom-storefronts/building-with-the-storefront-api/api-exploration/graphiql-storefront-api
 */

class Shopify {
    constructor() {
        this.cart = null;
        this.endpoint = '/shopify.php';

        this.cartFields = `
            checkoutUrl
            id
            lines(first: 100) {
                edges {
                    node {
                        attributes {
                            key
                            value
                        }
                        id
                        merchandise {
                            ... on ProductVariant {
                                id
                                title
                                price {
                                    amount
                                    currencyCode
                                }
                                product {
                                    id
                                    featuredImage {
                                        src
                                        transformedSrc(maxHeight: 256, maxWidth: 256)
                                    }
                                    handle
                                    options {
                                        name
                                        values
                                    }
                                    productType
                                    title
                                }
                                selectedOptions {
                                    name
                                    value
                                }
                            }
                        }
                        quantity
                    }
                }
            }
            note`;
    }

    async addToCart(merchandiseId, quantity = 1, attributes = []) {
        if (!this.cart) {
            console.error('Shopify Cart object not initialized.');
            return false;
        }

        const query = `
            mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
                cartLinesAdd(cartId: $cartId, lines: $lines) {
                    cart {
                        ${this.cartFields}
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`;

        const res = await this.query(query, {
            cartId: this.cart.id,
            lines: {
                attributes,
                merchandiseId,
                quantity,
            },
        });

        if (res.cartLinesAdd?.cart && res.cartLinesAdd?.userErrors?.length === 0) {
            this.updateCart(res.cartLinesAdd.cart);
            return true;
        }

        return false;
    }

    async getCart(cartId) {
        const query = `
            query cart($id: ID!) {
                cart(id: $id) {
                    ${this.cartFields}
                }
            }`;

        const res = await this.query(query, {
            id: window.btoa(cartId),
        });

        return res?.cart ?? null;
    }

    async getOrCreateCart() {
        const cartId = localStorage.cartId ?? null;

        if (cartId) {
            this.cart = await this.getCart(cartId);
        }

        if (!this.cart) {
            const query = `
                mutation {
                    cartCreate(input: {}) {
                        cart {
                            ${this.cartFields}
                        }
                    }
                }`;

            const res = await this.query(query);

            if (res.cartCreate?.cart) {
                this.cart = res.cartCreate.cart;

                if (this.cart?.id) {
                    localStorage.cartId = this.cart.id;
                }
            }
        }

        document.dispatchEvent(
            new CustomEvent('shopify:cartupdate', {
                detail: {
                    cart: this.cart,
                },
            })
        );

        return this.cart;
    }

    async query(query, variables = {}) {
        const res = await fetch(this.endpoint, {
            body: JSON.stringify({
                query: query.trim(),
                variables,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await res.json();

        if (data.errors) {
            data.errors.forEach(error => {
                console.error(error.message);
            });

            return null;
        }

        return data.data;
    }

    updateCart(cart) {
        this.cart = cart;

        document.dispatchEvent(
            new CustomEvent('shopify:cartupdate', {
                detail: {
                    cart,
                },
            })
        );
    }

    async removeLineItem(id) {
        if (!this.cart) {
            console.error('Shopify Cart object not initialized.');
            return false;
        }

        const query = `
            mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                    cart {
                        ${this.cartFields}
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`;

        const res = await this.query(query, {
            cartId: this.cart.id,
            lineIds: [id],
        });

        if (res.cartLinesRemove?.cart && res.cartLinesRemove?.userErrors?.length === 0) {
            this.updateCart(res.cartLinesRemove.cart);
            return true;
        }

        return false;
    }

    async updateLineItemQuantity(id, quantity, attributes = []) {
        if (!this.cart) {
            console.error('Shopify Cart object not initialized.');
            return false;
        }

        const query = `
            mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
                cartLinesUpdate(cartId: $cartId, lines: $lines) {
                    cart {
                        ${this.cartFields}
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`;

        const res = await this.query(query, {
            cartId: this.cart.id,
            lines: {
                attributes,
                id,
                quantity,
            },
        });

        if (res.cartLinesUpdate?.cart && res.cartLinesUpdate?.userErrors?.length === 0) {
            this.updateCart(res.cartLinesUpdate.cart);
            return true;
        }

        return false;
    }
}

function ShopifyCartUpdateEvent(event) {
    return event.detail.cart;
}

function ShopifyError(error) {
    return error.message;
}

function ShopifyLineItemAttribute(key, value) {
    return { key, value };
}

function ShopifyPrice(amount, currencyCode) {
    return { amount, currencyCode };
}

function ShopifyPriceAdjustment(adjustmentValue) {
    return { adjustmentValue };
}

function SellingPlan(discount, discountType, id, intervalDays, intervalWeeks, name) {
    return { discount, discountType, id, intervalDays, intervalWeeks, name };
}

export { Shopify, ShopifyCartUpdateEvent, ShopifyError, ShopifyLineItemAttribute, ShopifyPrice, ShopifyPriceAdjustment, SellingPlan };
