export default class Nav {
    $el;
    $cartNumItems;
    resizeObserver;

    constructor($el) {
        this.$el = $el;
        this.$cartNumItems = $el.querySelector('.js-navCartNumItems');

        this.$cartNumItems.innerText = localStorage.cartNumItems ?? 0;

        this.initListeners();
    }

    initListeners() {
        this.resizeObserver = new ResizeObserver(this.onResize.bind(this));
        this.resizeObserver.observe(this.$el);

        document.addEventListener('shopify:cartupdate', this.onShopifyCartUpdate.bind(this), false);
    }

    onResize() {
        const height = this.$el.getBoundingClientRect().height;
        document.body.style.setProperty('--nav-height', height + 'px');
    }

    onShopifyCartUpdate(e) {
        const cartNumItems = e.detail.cart.lines.edges.length;
        this.$cartNumItems.innerText = cartNumItems;
        localStorage.cartNumItems = cartNumItems;
    }
}
