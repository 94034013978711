export default class Gallery {
    constructor($el) {
        this.$el = $el;
        this.$$image = this.$el.querySelectorAll('.js-galleryImage');
        this.$count = this.$el.querySelector('.js-galleryCount');
        this.$navNext = this.$el.querySelector('.js-galleryNavNext');
        this.$navPrev = this.$el.querySelector('.js-galleryNavPrev');
        this.$images = this.$el.querySelector('.js-galleryImages');
        this.currentIndex = 0;
        this.numImages = this.$$image.length;

        if (this.numImages === 1) {
            this.$navNext.disabled = true;
            this.$navPrev.disabled = true;
        } else {
            this.$count.textContent = `${this.currentIndex + 1} / ${this.numImages}`;
        }

        this.initListeners();
    }

    initListeners() {
        this.$navNext.addEventListener('click', e => {
            if (this.currentIndex < this.$$image.length) {
                this.scrollToIndex(this.currentIndex + 1);
            }
        });

        this.$navPrev.addEventListener('click', e => {
            if (this.currentIndex > 0) {
                this.scrollToIndex(this.currentIndex - 1);
            }
        });

        this.$images.addEventListener('scroll', this.onImagesScroll.bind(this), false);
    }

    onImagesScroll() {
        const index = Math.round(this.$images.scrollLeft / this.$el.offsetWidth);

        if (index !== this.currentIndex) {
            this.setIndex(index);
        }
    }

    scrollToIndex(index) {
        this.$images.scrollTo({
            behavior: 'smooth',
            left: this.$el.offsetWidth * index,
            top: 0,
        });
    }

    setIndex(index) {
        this.$count.textContent = `${index + 1} / ${this.numImages}`;
        this.$navPrev.disabled = index === 0;
        this.$navNext.disabled = index === this.numImages - 1;
        this.currentIndex = index;
    }
}
