export default class Countdown {
    $el;
    $d;
    $h;
    $m;
    $s;
    interval;
    now;
    to;

    constructor($el) {
        this.$el = $el;
        this.$d = $el.querySelector('.js-countdownD'); // Days
        this.$h = $el.querySelector('.js-countdownH'); // Hours
        this.$m = $el.querySelector('.js-countdownM'); // Minutes
        this.$s = $el.querySelector('.js-countdownS'); // Seconds
        this.$ms = $el.querySelector('.js-countdownMs'); // Milliseconds

        if (this.$el.dataset.countdownNow ?? null) {
            const countdownNow = this.$el.dataset.countdownNow;
            // this.now = new Date(countdownNow).toLocaleString('en-US', { timeZone: 'Europe/Amsterdam' });
            this.now = new Date(countdownNow);
        }

        if (this.$el.dataset.countdownTo ?? null) {
            const countdownTo = this.$el.dataset.countdownTo;
            // this.to = new Date(countdownTo).toLocaleString('en-US', { timeZone: 'Europe/Amsterdam' });
            this.to = new Date(countdownTo);
        }

        if (this.to && this.now) {
            this.interval = setInterval(this.update.bind(this), 10);
            // requestAnimationFrame(this.update.bind(this));
            this.update();
        } else {
            console.error('The required data-countdown-to attribute is missing on:', this.$el);
        }
    }

    getTimeRemaining() {
        const total = (Date.parse(this.to) - Date.parse(new Date())) / 1000;
        const milliseconds = 1000 - new Date().getMilliseconds();
        const seconds = Math.floor(total % 60);
        const minutes = Math.floor((total / 60) % 60);
        const hours = Math.floor((total / (60 * 60)) % 24);
        const days = Math.floor(total / (60 * 60 * 24));

        return {
            total,
            days,
            hours,
            minutes,
            seconds,
            milliseconds,
        };
    }

    update() {
        const remaining = this.getTimeRemaining();

        if (this.$d) {
            if (remaining.days > 0) {
                this.$d.innerText = ('0' + remaining.days).slice(-2);
            } else {
                this.$d.parentElement.classList.add('is-hidden');
            }
        }

        if (this.$h) {
            this.$h.innerText = ('0' + remaining.hours).slice(-2);
        }

        if (this.$m) {
            this.$m.innerText = ('0' + remaining.minutes).slice(-2);
        }

        if (this.$s) {
            this.$s.innerText = ('0' + remaining.seconds).slice(-2);
        }

        if (this.$ms) {
            this.$ms.innerText = ('00' + remaining.milliseconds).slice(-3).slice(0, 2);
        }

        if (remaining.total <= 0) {
            clearInterval(this.interval);
        }
    }
}
