/**
 * https://community.shopify.com/c/shopify-design/cart-use-permalinks-to-pre-load-the-cart/td-p/613702#adding-a-buy-this-link-to-a-blog-post
 */

export default class Product {
    constructor($el) {
        this.$el = $el;
        this.$addToCart = $el.querySelector('button[type="submit"]');
        this.$buyNow = $el.querySelector('.js-productBuyNow');
        this.$variantId = $el.querySelector('input[name="variant_id"]');
        this.$$options = $el.querySelectorAll('.js-productOption');
        this.isReady = false;
        this.isSoldOut = false;
        this.options = JSON.parse($el.querySelector('script[data-product-options]')?.innerText ?? '[]');
        this.productType = $el.dataset.productType ?? null;
        this.url = $el.dataset.url ?? null;
        this.variants = JSON.parse($el.querySelector('script[data-product-variants]')?.innerText ?? '[]');

        if (this.variants.length > 1) {
            this.updateVariant();
        } else {
            this.$variantId.value = `gid://shopify/ProductVariant/${this.variants[0].id}`;
            this.isReady = true;
            this.isSoldOut = this.variants[0].inventory_quantity < 1;
            this.updateBuyNowLink(this.variants[0]);
        }

        this.checkCartStatus();
        this.initListeners();
    }

    checkCartStatus() {
        if (this.productType === 'vintage') {
            const variantId = this.$variantId.value;
            const $lineItem = document.querySelector(`#cart .js-lineItem[data-id="${variantId}"]`);
            this.isInCart = $lineItem !== null;
        }
    }

    getOptionValue(position) {
        const $option = this.$el.querySelector(`[data-product-option="${position}"]`);

        if ($option) {
            const $radio = $option.querySelector('input[type="radio"]:checked');

            if ($radio) {
                return $radio.value;
            }
        }

        return null;
    }

    initListeners() {
        document.addEventListener('cart:rendered', this.checkCartStatus.bind(this), false);

        this.$$options.forEach($option => {
            $option.querySelectorAll('input[type="radio"]').forEach($radio => {
                $radio.addEventListener('change', this.updateVariant.bind(this));
            });
        });

        this.$addToCart.addEventListener('click', this.onAddToCart.bind(this));
    }

    onAddToCart(e) {
        e.preventDefault();

        const attributes = [
            {
                key: '_url',
                value: this.url,
            },
        ];
        const merchandiseId = this.$variantId.value.trim();
        const quantity = 1;

        if (merchandiseId !== '') {
            document.dispatchEvent(
                new CustomEvent('product:addtocart', {
                    detail: {
                        merchandiseId,
                        quantity,
                        attributes,
                    },
                })
            );
        }
    }

    updateBuyNowLink(variant) {
        this.$buyNow.href = `https://nhogirlshop.myshopify.com/cart/${variant.id}:1`; // f64c5e.myshopify.com
    }

    updateVariant(e) {
        let selection = [];

        for (const option of this.options) {
            const $checkedRadio = this.$el.querySelector(`[data-product-option="${option.position}"] input[type="radio"]:checked`);
            const value = $checkedRadio?.value ?? null;
            selection[option.position] = value;
        }

        selection = selection.filter(n => n);
        this.isReady = selection.length === this.options.length;

        if (selection.length === this.options.length) {
            const title = selection.join(' / ');
            const variant = _.find(this.variants, { title });

            if (variant) {
                if (variant.inventory_quantity < 1) {
                    this.$variantId.value = '';
                    this.isSoldOut = true;
                } else {
                    this.$variantId.value = `gid://shopify/ProductVariant/${variant.id}`;
                    this.isSoldOut = false;
                    this.updateBuyNowLink(variant);
                }
            }
        }
    }

    /**
     *  Getters & setters
     */

    get isInCart() {
        return this.$el.classList.contains('is-inCart');
    }

    set isInCart(isInCart) {
        this.$addToCart.disabled = isInCart;
        this.$el.classList.toggle('is-inCart', isInCart);
    }

    get isReady() {
        return this.$el.classList.contains('is-ready');
    }

    set isReady(isReady) {
        this.$addToCart.disabled = !isReady;
        this.$el.classList.toggle('is-ready', isReady);
    }

    get isSoldOut() {
        return this.$el.classList.contains('is-soldOut');
    }

    set isSoldOut(isSoldOut) {
        this.$addToCart.disabled = isSoldOut;
        this.$el.classList.toggle('is-soldOut', isSoldOut);
    }
}
