import { Shopify } from '@js/lib/Shopify';
import Utils from '@js/lib/Utils';
import LineItem from '@js/components/LineItem';

export default class Cart {
    $checkout;
    $el;
    $lineItems;
    $subtotal;
    $toggle;
    isLoading;
    shopify;

    constructor($el) {
        this.$el = $el;
        this.$checkout = this.$el.querySelector('.js-cartCheckout');
        this.$lineItems = this.$el.querySelector('.js-cartLineItems');
        this.$subtotal = this.$el.querySelector('.js-cartSubtotal');
        this.$toggle = document.getElementById('toggleCart');
        this.isLoading = true;
        this.shopify = new Shopify();

        this.initListeners();
        this.initCart();
    }

    async initCart() {
        const cart = await this.shopify.getOrCreateCart();

        if (cart) {
            this.isLoading = false;
        }
    }

    initListeners() {
        document.addEventListener('product:addtocart', this.onProductAddToCart.bind(this), false);
        document.addEventListener('shopify:cartupdate', this.onShopifyCartUpdate.bind(this), false);
        this.$lineItems.addEventListener('lineitem:remove', this.onLineItemRemove.bind(this), false);
    }

    onLineItemRemove(e) {
        const $lineItem = e.target.closest('.js-lineItem');
        const id = e.detail.id;

        $lineItem.addEventListener(
            'animationend',
            () => {
                this.shopify.removeLineItem(id);
                $lineItem.remove();
            },
            false
        );

        $lineItem.classList.add('is-removing');
    }

    onProductAddToCart(e) {
        this.show();
        this.isLoading = true;
        this.shopify.addToCart(e.detail.merchandiseId, e.detail.quantity, e.detail.attributes ?? []);
    }

    onShopifyCartUpdate(e) {
        this.render(e.detail.cart);
    }

    render(cart) {
        this.$lineItems.innerHTML = '';
        this.$checkout.href = cart.checkoutUrl ?? '#';

        let $lineItem;
        let edge;

        if (cart.lines?.edges?.length) {
            this.isEmpty = false;

            for (edge of cart.lines.edges) {
                // for (edge of cart.lines.edges.reverse()) {
                $lineItem = LineItem.create(edge.node);
                this.$lineItems.appendChild($lineItem);
            }
        } else {
            this.isEmpty = true;
        }

        document.dispatchEvent(new CustomEvent('cart:rendered'));
        this.loading = false;
        this.updateSubtotal();
    }

    show() {
        this.$toggle.checked = true;
    }

    updateSubtotal() {
        const $$lineItem = this.$el.querySelectorAll('.js-lineItem');
        let $lineItem;
        let price;
        let qty;
        let subtotal = 0;

        for ($lineItem of $$lineItem) {
            price = +$lineItem.dataset.price;
            qty = +$lineItem.dataset.qty;
            subtotal += price * qty;
        }

        this.$subtotal.innerText = Utils.formatCurrency(+subtotal);
    }

    /**
     * Getters & setters
     */

    get isEmpty() {
        return this.$el.classList.contains('is-empty');
    }

    set isEmpty(isEmpty) {
        this.$el.classList.toggle('is-empty', isEmpty);
    }

    get isLoading() {
        return this.$el.classList.contains('is-loading');
    }

    set isLoading(isLoading) {
        this.$el.classList.toggle('is-loading', isLoading);
    }
}
