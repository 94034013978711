import '@css/app.scss';

import axios from 'axios';
import lodash from 'lodash';

import App from '@js/App';
import Nav from './components/Nav';
import Cart from './components/Cart';
import CookieConsent from './components/CookieConsent';
import Countdown from './components/Countdown';
import Gallery from './components/Gallery';
import Menu from './components/Menu';
import Notice from './components/Notice';
import Modal from './components/Modal';
import Product from './components/Product';
import XhrForm from './components/XhrForm';

window._ = lodash;
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.addEventListener('DOMContentLoaded', () => {
    window.__APP__ = new App([
        {
            component: Cart,
            name: 'cart',
        },
        {
            component: CookieConsent,
            name: 'cookieConsent',
        },
        {
            component: Countdown,
            name: 'countdown',
        },
        {
            component: Gallery,
            name: 'gallery',
        },
        {
            component: Menu,
            name: 'menu',
        },
        {
            component: Modal,
            name: 'modal',
        },
        {
            component: Nav,
            name: 'nav',
        },
        {
            component: Notice,
            name: 'notice',
        },
        {
            component: Product,
            name: 'product',
        },
        {
            component: XhrForm,
            name: 'xhrForm',
        },
    ]);
});

// Vite HMR
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}
