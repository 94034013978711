export default class XhrForm {
    $el;
    method;
    request;
    url;

    constructor($el) {
        this.$el = $el;
        this.method = this.$el.getAttribute('method') ?? 'post';
        this.request = null;
        this.url = this.$el.getAttribute('action');

        this.initListeners();
    }

    initListeners() {
        this.$el.addEventListener('submit', this.onSubmit.bind(this));
    }

    async onSubmit(e) {
        e.preventDefault();

        if (this.isWaiting) {
            return;
        }

        const request = {
            data: new FormData(this.$el),
            method: this.method,
            url: this.url,
        };

        if (this.$el.dataset.xhrFormConfirm) {
            if (!window.confirm(this.$el.dataset.xhrFormConfirm)) {
                return false;
            }
        }

        this.isSuccess = false;
        this.isWaiting = true;

        try {
            const res = await axios(request);
            this.isSuccess = true;
            this.isWaiting = false;

            if (res.data.redirect !== undefined) {
                window.location = res.data.redirect;
            }
        } catch (error) {
            this.isWaiting = false;

            if (error.response.status === 422 && error.response.data.errors) {
                this.displayErrors(error.response.data.errors);
            }
        }
    }

    displayErrors(errors) {
        this.resetErrors();

        for (const key in errors) {
            const $input = this.$el.querySelector(`[name="${key}"]`);

            if ($input) {
                const $error = Object.assign(document.createElement('div'), {
                    className: 'formError',
                    textContent: errors[key].shift(),
                });

                const $field = $input.closest('.formField');
                $field.classList.add('has-error');
                $field.append($error);
            }
        }
    }

    resetErrors() {
        this.$el.querySelectorAll('.formError').forEach(($error) => {
            const $field = $error.parentNode;
            $field.classList.remove('has-error');
            $field.removeChild($error);
        });
    }

    /**
     * Getters & setters
     */

    get isSuccess() {
        return this.$el.classList.contains('is-success');
    }

    set isSuccess(isSuccess) {
        return this.$el.classList.toggle('is-success', isSuccess);
    }

    get isWaiting() {
        return this.$el.classList.contains('is-waiting');
    }

    set isWaiting(isWaiting) {
        this.$el.querySelectorAll('button[type="submit"]').forEach(($submit) => {
            if (isWaiting) {
                $submit.setAttribute('disabled', '');
            } else {
                $submit.removeAttribute('disabled');
            }
        });

        return this.$el.classList.toggle('is-waiting', isWaiting);
    }
}
