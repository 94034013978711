export default class Utils {
    static formatCurrency(number) {
        return ('€ ' + number).replace('.00', '').replace('.', ',');

        /*
        const format = Intl.NumberFormat(document.documentElement.lang, {
            currency: 'EUR',
            style: 'currency',
        });

        return format.format(number);
        */
    }

    static parseString(template, values) {
        const regExp = /{+\s?([^{}\s]*)\s?}+/g;

        return template.replace(regExp, (substring, key) => {
            return values[key] ?? '';
        });
    }

    static setElementProperty($root, selector, property, value) {
        $root.querySelectorAll(selector).forEach($el => {
            if ($el[property] !== undefined) {
                $el[property] = value;
            }
        });
    }
}
