/**
 * https://developers.google.com/tag-platform/devguides/consent#gtag.js_1
 */

export default class CookieConsent {
    constructor($el) {
        this.$el = $el;
        this.$btnAccept = this.$el.querySelector('.js-cookieConsentBtnAccept');
        this.$btnDecline = this.$el.querySelector('.js-cookieConsentBtnDecline');
        this.initListeners();
    }

    initListeners() {
        this.$btnAccept.addEventListener('click', this.onClickAccept.bind(this));
        this.$btnDecline.addEventListener('click', this.onClickDecline.bind(this));
    }

    onClickAccept() {
        const consent = {
            ad_personalization: 'granted',
            ad_storage: 'granted',
            ad_user_data: 'granted',
            analytics_storage: 'granted',
        };

        this.update(consent);
    }

    onClickDecline() {
        const consent = {
            ad_personalization: 'denied',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            analytics_storage: 'denied',
        };

        this.update(consent);
    }

    update(consent) {
        if (window.gtag) {
            gtag('consent', 'update', consent);
        }

        localStorage.setItem('cookie-consent', JSON.stringify(consent));
        this.$el.classList.add('is-hide');
    }
}
