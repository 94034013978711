import Utils from '@js/lib/Utils';

export default class LineItem {
    id;

    static create(lineItem) {
        let options = [];
        const product = lineItem.merchandise.product;

        // Options
        if (lineItem.merchandise.selectedOptions.length) {
            for (const option of lineItem.merchandise.selectedOptions) {
                if (option.name !== 'Title') {
                    options.push(option.value);
                }
            }
        }

        // Get product URL from attributes
        let href = _.find(lineItem.attributes, attr => {
            return attr.key == '_url';
        });

        if (href) {
            href = href.value;
        }

        const data = {
            href,
            image: product.featuredImage.src,
            options: options.join(' / '),
            price: Utils.formatCurrency(+lineItem.merchandise.price.amount),
            qty: lineItem.quantity > 1 ? lineItem.quantity : '',
            title: product.title,
        };

        const $template = document.querySelector('.js-cartTemplateLineItem');
        const templateHTML = $template.innerHTML.trim();
        const lineItemHTML = Utils.parseString(templateHTML, data);

        // Create DOM element
        const $div = document.createElement('div');
        $div.innerHTML = lineItemHTML;
        const $lineItem = $div.firstChild;

        // Set data attributes for subtotal and availability
        $lineItem.dataset.id = lineItem.merchandise.id;
        $lineItem.dataset.price = +lineItem.merchandise.price.amount;
        $lineItem.dataset.qty = lineItem.quantity;

        // Set remove listener
        const $remove = $lineItem.querySelector('.js-lineItemRemove');
        $remove.addEventListener(
            'click',
            e => {
                e.target.dispatchEvent(
                    new CustomEvent('lineitem:remove', {
                        bubbles: true,
                        detail: {
                            id: lineItem.id,
                        },
                    })
                );
            },
            false
        );

        return $lineItem;
    }
}
